import {Component, Inject, LOCALE_ID, OnInit, Renderer2} from '@angular/core';
import { ConfigService } from '../@vex/services/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import icLayers from '@iconify/icons-ic/twotone-layers';
import icDashboard from '@iconify/icons-ic/twotone-dashboard';
import icPerson from '@iconify/icons-ic/twotone-person';
import icCategory from '@iconify/icons-ic/twotone-category';
import icSupervised_user_circle from '@iconify/icons-ic/twotone-supervised-user-circle';
import IcDonut_large from '@iconify/icons-ic/twotone-donut-large';
import IcGroup from '@iconify/icons-ic/twotone-group';
import IcPermdatasetting from '@iconify/icons-ic/twotone-perm-data-setting';
import IcNotification from '@iconify/icons-ic/twotone-notifications';
import { LayoutService } from '../@vex/services/layout.service';
import { ActivatedRoute,Router, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { Style, StyleService } from '../@vex/services/style.service';
import { ConfigName } from '../@vex/interfaces/config-name.model';
import {TranslateService} from '@ngx-translate/core';
import '../assets/i18n/en.json';
import '../assets/i18n/nb.json';
import {LanguageService} from '../@vex/services/language.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalStoreService } from 'src/@vex/services/local-store.service';
import { AuthServiceLocal } from 'src/@vex/services/auth.service';
// import { LocalStoreService } from './core/auth/local-store.service';
import { MatSnackBar } from '@angular/material/snack-bar'; 


@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'Empida Organisation Panel';
  style = Style;
  sidebarText = {
    ngo: '',
    dashboard: ''
  };
  version: any;

  constructor(private configService: ConfigService,
              private styleService: StyleService,
              private renderer: Renderer2,
              private platform: Platform,
              @Inject(DOCUMENT) private document: Document,
              @Inject(LOCALE_ID) private localeId: string,
              private layoutService: LayoutService,
              private route: ActivatedRoute, private langService: LanguageService,
              private splashScreenService: SplashScreenService,
              private translate: TranslateService, 
              private matIconRegistry: MatIconRegistry,
              private router : Router, public _snackBar : MatSnackBar,
              public _authService : AuthServiceLocal,
              private store: LocalStoreService,
              private domSanitizer: DomSanitizer) {

              
          
              // console.log("Slack integration Done. 0.2");
              // console.log("this.localeId -->>", this.localeId)

    Settings.defaultLocale = this.localeId;
    // this.styleService.setStyle(this.style.dark);
    this.setLanguage();

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    showConfigButton: false,
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    
    this.route.queryParams.subscribe((res: any) => {
      // console.log("THis is res checking having query params or not ", res)
    })  
    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap.pipe(
      map(queryParamMap => queryParamMap.has('rtl') && coerceBooleanProperty(queryParamMap.get('rtl'))),
    ).subscribe(isRtl => {
      this.document.body.dir = isRtl ? 'rtl' : 'ltr';
      this.configService.updateConfig({
        rtl: isRtl
      });
    });

    

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('layout'))
    ).subscribe(queryParamMap => this.configService.setConfig(queryParamMap.get('layout') as ConfigName));

    

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('style'))
    ).subscribe(queryParamMap => this.styleService.setStyle(queryParamMap.get('style') as Style));

    

    this.router.events
    .pipe(filter((e) => e instanceof NavigationEnd))
    .subscribe((e: NavigationEnd) => {
        // this.pageUrl = e.url;
       

        let localVersion =
            this.store.getItem('loggedUser')?.version;
        this.getApiVerison();

        setTimeout(() => {
          

            // if (
            //     this.version != localVersion &&
            //     this.version != undefined
            // ) {
            //     let user = this.store.getItem('loggedUser');
            //     user.version = this.version;
              
            //     this.store.setItem('loggedUser', user);

            //     window.location.reload();
            //     if (window.caches && window.caches.keys) {
            //         caches.keys().then(function (cacheNames) {
            //             cacheNames.forEach(function (cacheName) {
            //                 caches.delete(cacheName);
            //             });
            //         });
            //     }
            // } else if (this.version == localVersion) {
            //     console.log(' the api is updated now');
            // }
        }, 2000);
    });

    

    // console.log("you are app component ")
  }

  ngOnInit(): void {
    // this.getText();
    this.registerSvgIcons();

     
  }

  /**
   * function used to set Language
   */
  setLanguage() {
    this.translate.addLangs(['en', 'nb']);
    this.translate.setDefaultLang('en');
    const browserLang = this.translate.getBrowserLang();
    this.translate.use(browserLang.match(/en|no/) ? browserLang : 'en');
  }

  /**
   * function used to register Svg icons
   */
  registerSvgIcons() {
    this.matIconRegistry.addSvgIcon(
        'svgDashboard',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/dashboard.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'svgChallenge',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/challenge.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'svgLaunch',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/launch_icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'svgTheme',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/theme_icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'svgTeam',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/team.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'svgEmployee',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/employee.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'svgReward',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/reward.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'svgNgo',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/ngo.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'svgPc',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/ngo.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'svgRole',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/role.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'svgSendNotification',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/send-notification.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'svgSlackIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/sidenav-slack.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'svgWellness',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/wellness.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'svgColleague',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/praise.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'svgEvent',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/event.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'svgDepartment',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/department_icon.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'svgCoaching',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/coaching_icon.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'svgManager',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/manager-ai.svg')
    );
  }

  getApiVerison() {
    this._authService.getCurrentApiVersion().subscribe((res: any) => {
        if (res.status == 200) {
            this.version = res.data.version;
        } else {
            this._snackBar.open(res.message, 'Okay', { duration: 3000 });
        }
    });
}
}

<div class=" border-b border-[#E6E6E6] pt-0 pb-4 mx-0">
    <div class="flex relative justify-between">
        <h2 class="m-0 w-full text-xl font-semibold ">{{'Filter'}}</h2>
        <div class="absolute right-0" style="top: -4px">
            <button mat-icon-button (click)="_matDialogRef.close()" aria-label="Close dialog">
                <mat-icon [icIcon]="icClose"></mat-icon>
            </button>
        </div>
    </div>
</div>
<mat-dialog-content class="">
    <div class="surveyTypeSelection pb-0 mt-4">
        <h4 class=" text-sm font-semibold">{{'My teams'}}</h4>

        <mat-radio-group aria-labelledby="example-radio-group-label" class="px-2"
            style="max-height: 200px; min-height: 60px ; padding: 8px 10px;"
            class="flex gap-4  overflow-x-scroll flex-wrap" [(ngModel)]="selectedMember">
            <mat-radio-button class="" *ngFor="let item of teamList" [value]="item.id"> 
                <span [ngStyle]="{'font-weight' :selectedMember !== item.id ? '400': '600'}">{{item.teamName}}</span>  
            </mat-radio-button>
        </mat-radio-group>

    </div>

    <div class="surveyTypeSelection">
        <h4 class="text-sm font-semibold ">{{'By Gender'}}</h4>

        <div class="flex gap-10">

            <!-- <mat-checkbox class="example-margin" [(ngModel)]="isAllSelected"
                (change)="selectOnly('All')">
                <span [ngStyle]="{'font-weight' : !isAllSelected ? '400': '600'}">{{'All'}}</span> 
            </mat-checkbox>
            <mat-checkbox class="example-margin" [(ngModel)]="isMaleSelected"
                (change)="selectOnly('Male')">{{'Male'}}</mat-checkbox>
            <mat-checkbox class="example-margin" [(ngModel)]="isFemaleSelected"
                (change)="selectOnly('Female')">{{'Female'}}</mat-checkbox> -->
            <mat-radio-group aria-labelledby="example-radio-group-label" class="px-2"
            style="max-height: 200px; min-height: 60px ; padding: 8px 10px!important;"
            class="flex gap-4  overflow-x-scroll flex-wrap" [(ngModel)]="selectedGender">
                <mat-radio-button  class="" [value]="''">
                    <span [ngStyle]="{'font-weight' :selectedGender !== '' ? '400': '600'}">{{'All'}}</span> 
                </mat-radio-button>
                <mat-radio-button class="" [value]="1">
                    <span [ngStyle]="{'font-weight' :selectedGender !== 1 ? '400': '600'}">{{'Male'}}</span> 
                </mat-radio-button>
                <mat-radio-button class="" [value]="2">
                    <span [ngStyle]="{'font-weight' :selectedGender !== 2 ? '400': '600'}">{{'Female'}}</span> 
                </mat-radio-button>
            </mat-radio-group>

        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="justify-end flex pb-4 pt-2">
    <button mat-button class="bg-themeColor text-white px-6 " (click)="submit()">Apply</button>

</mat-dialog-actions>
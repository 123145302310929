
<div matDialogTitle class="relative ">
    <div class="flex justify-between pt-2 m-auto text-center slack-section" style="width: 95%;">
        <div class="text-left">
            <img class=" sm:w-64" src="assets/img/on-board/slack_logo.svg" alt="">
            <p class="mt-4 text-lg font-semibold text-black md:text-xl">Invite Your Team Members</p>
        </div>
        
        <div class="flex flex-row items-end search-bar">
            <div class="px-4 bg-card " >
                <!-- <ic-icon [icIcon]="icSearch" size="20px"></ic-icon> -->
                <input [formControl]="searchCtrl" class="w-full py-3 bg-transparent border-0 outline-none"
                    placeholder="Search members" type="search">
            </div>
        </div>

        <div class="search-btn">
            <button mat-flat-button (click)="onInviteMembers()" [disabled]="isBtnDisabled()" matDialogClose>Invite</button>
        </div>
        
    </div>
</div>

<mat-dialog-content class="slack-channel-list" >
  
    <div  style="justify-content: left;" class="grid items-start grid-cols-1 gap-5 pt-5 align-middle md:grid-cols-2 xl:grid-cols-3 dialog-content focusAreaData md:pt-6 md:gap-10" *ngIf="this.filteredData?.length;">
            <mat-checkbox matRipple *ngFor="let member of this.filteredData; let i = index" [checked]="member.isSelected" (change)="onAddMember(i, $event)" labelPosition="before" class="member_item">
                <div>
                    <img [src]="member.profile.image_72" alt="">
                    <span class="text-lg font-semibold text-left text-black break-all whitespace-pre-wrap">{{member.name}} </span>
                </div>
            </mat-checkbox>
    </div>
    
    <div *ngIf="!filteredData?.length" class="flex items-center justify-center h-48 ">
        <span class="absolute text-2xl font-semibold">No Members Founds </span>
    </div>
</mat-dialog-content>

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import icClose from '@iconify/icons-ic/close';

@Component({
  selector: 'vex-actions-detail-dialog',
  templateUrl: './actions-detail-dialog.component.html',
  styleUrls: ['./actions-detail-dialog.component.scss']
})
export class ActionsDetailDialogComponent implements OnInit {

  icClose = icClose;
  heading : string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: any,
    public _matDialogRef: MatDialogRef<ActionsDetailDialogComponent>
  ) { 
    console.log("_data", _data);
    this.heading = this._data.heading;
  }

  ngOnInit(): void {
  }

}

<div class="matDialogTitle border-b border-[#E6E6E6] pt-0 pb-4 mx-0">
    <div class="flex relative justify-between">
        <h2 class="m-0 w-full text-2xl font-bold text-center">{{heading}}</h2>
        <div class="absolute right-0" style="top: -4px">
            <button mat-icon-button (click)="_matDialogRef.close()" aria-label="Close dialog">
                <mat-icon [icIcon]="icClose"></mat-icon> 
            </button>
        </div>
    </div> 
</div>
<mat-dialog-content class="max-h-lg" fusePerfectScrollbar>
    <div class="flex md:items-start sm:items-center items-center gap-4 md:flex-row pb-6 sm:flex-col flex-col justify-center">
        <img [src]="_data.detailData.challengeImage" class="w-56" style="aspect-ratio: 1/1;" alt="">
        <div class="pt-6 sm:mb-16 md:mb-0 mb-16">
            <h2 class="font-bold text-xl mb-4">{{_data.detailData.title}}</h2>
            <div [innerHTML]="_data.detailData.description"></div>
        </div>
    </div>
</mat-dialog-content>
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MenuItem } from '../interfaces/menu-item.interface';
import { trackById } from '../../../../utils/track-by';
import icPerson from '@iconify/icons-ic/twotone-person';
import icSettings from '@iconify/icons-ic/twotone-settings';
import icAccountCircle from '@iconify/icons-ic/twotone-account-circle';
import icMoveToInbox from '@iconify/icons-ic/twotone-move-to-inbox';
import icListAlt from '@iconify/icons-ic/twotone-list-alt';
import icTableChart from '@iconify/icons-ic/twotone-table-chart';
import icCheckCircle from '@iconify/icons-ic/twotone-check-circle';
import icAccessTime from '@iconify/icons-ic/twotone-access-time';
import icDoNotDisturb from '@iconify/icons-ic/twotone-do-not-disturb';
import icOfflineBolt from '@iconify/icons-ic/twotone-offline-bolt';
import icChevronRight from '@iconify/icons-ic/twotone-chevron-right';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import icBusiness from '@iconify/icons-ic/twotone-business';
import icVerifiedUser from '@iconify/icons-ic/twotone-verified-user';
import icLock from '@iconify/icons-ic/twotone-lock';
import icNotificationsOff from '@iconify/icons-ic/twotone-notifications-off';
import icFeed from '@iconify/icons-ic/twotone-rss-feed';
import { Icon } from '@visurel/iconify-angular';
import { PopoverRef } from '../../../../components/popover/popover-ref';
import {LocalStoreService} from '../../../../services/local-store.service';
import {AuthServiceLocal} from '../../../../services/auth.service';
import {TranslateService} from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { parseJSON } from 'date-fns';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { CommonInfoDialogComponent } from 'src/@vex/common-info-dialog/common-info-dialog.component';

export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: Icon;
  colorClass: string;
}

@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserDropdownComponent implements OnInit {
  items: MenuItem[] = [];
  userDropdown: MenuItem[] = [
    {
      id: '1',
      icon: icPerson,
      label: this.translate.instant('pageTitle.myProfile'),
      description: '',
      colorClass: 'text-primary',
      route: '/profile'
    },
    {
      id: '1',
      icon: icLock,
      label: this.translate.instant('pageTitle.changePassword'),
      description: '',
      colorClass: 'text-teal',
      route: '/change-password'
    },
  ];
  organisationDropdown: MenuItem[] = [
    {
      id: '1',
      icon: icPerson,
      label: this.translate.instant('pageTitle.myAccount'),
      description: '',
      colorClass: 'text-primary',
      route: '/my-account'
    },
    {
      id: '1',
      icon: icLock,
      label: this.translate.instant('pageTitle.changePassword'),
      description: '',
      colorClass: 'text-teal',
      route: '/change-password'
    },
   
  ];

  statuses: OnlineStatus[] = [
    // {
    //   id: 'online',
    //   label: 'Online',
    //   icon: icCheckCircle,
    //   colorClass: 'text-green'
    // },
    // {
    //   id: 'away',
    //   label: 'Away',
    //   icon: icAccessTime,
    //   colorClass: 'text-orange'
    // },
    // {
    //   id: 'dnd',
    //   label: 'Do not disturb',
    //   icon: icDoNotDisturb,
    //   colorClass: 'text-red'
    // },
    // {
    //   id: 'offline',
    //   label: 'Offline',
    //   icon: icOfflineBolt,
    //   colorClass: 'text-gray'
    // }
  ];

  activeStatus: OnlineStatus = this.statuses[0];

  trackById = trackById;
  icPerson = icPerson;
  icSettings = icSettings;
  icChevronRight = icChevronRight;
  icArrowDropDown = icArrowDropDown;
  icBusiness = icBusiness;
  icVerifiedUser = icVerifiedUser;
  icLock = icLock;
  icNotificationsOff = icNotificationsOff;
  icFeed = icFeed;
  AdminDetails : any;

  isChecked = 0
  constructor(
    private cd: ChangeDetectorRef, private translate: TranslateService,
    private popoverRef: PopoverRef<ToolbarUserDropdownComponent>,
    private store: LocalStoreService, private auth: AuthServiceLocal,
    private snackbar : MatSnackBar,private dialog: MatDialog,
    private  readonly matIconRegistry: MatIconRegistry,
    private readonly sanitizer: DomSanitizer,
              ) {
                this.matIconRegistry.addSvgIcon('slack-intergration', this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/slack-new-logo.svg'))
               }

  ngOnInit() {

   
      this.AdminDetails = JSON.parse(localStorage.getItem("loggedUser"));  
      console.log( "admin details ====>",this.AdminDetails)
      if (this.AdminDetails && this.AdminDetails?.isAdmin === 1) {
        this.items = this.organisationDropdown;
      } else {
        this.items = this.userDropdown;
      }
      
    

    console.log("AdminDetails  ===>",this.AdminDetails?.isFeedOn)
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.AdminDetails = this.auth.getUser();
    if (this.AdminDetails && this.AdminDetails?.isAdmin === 1) {
      this.items = this.organisationDropdown;
    } else {
      this.items = this.userDropdown;
    }
  }

  setStatus(status: OnlineStatus) {
    this.activeStatus = status;
    this.cd.markForCheck();
  }

  close(type) {
    if (type === 'logout'){
      this.store.clear();
    }
    this.popoverRef.close();
  }

  feedEnableDisable(value){
    const payload ={
      "isfeedOn" : value == 0 ? 1 : 0
    }
    this.auth.setFeedStatus(payload).subscribe((response: any) => { 
      console.log("response data ===>",response)
      if(response.status === 200){
      
        let temp :any = localStorage.getItem('loggedUser')
         temp = JSON.parse(temp)

         temp.isFeedOn = response.data.isFeedOn
         const tempdata:any = JSON.stringify(temp)

         console.log("temp ",tempdata)
          localStorage.setItem('loggedUser',tempdata)

        this.dialog.open(CommonInfoDialogComponent, {              
          width: '300px',              
          data: {
            title: " Feed status updated successfully",
            imageUrl:'assets/img/icons/common_successfully.svg'              
          }
        })
      }else{
        this.snackbar.open(response.message)
      }
     })
  }
}

<a *ngIf="isLink(item) && !isFunction(item.route)"
   [fragment]="item.fragment"
   (click)="onClickOnSidenavItem(item)"
   [routerLinkActiveOptions]=" item.label != 'Dashboard'  ?  [] : item.routerLinkActiveOptions || { exact: true }"
   [routerLink]="(this.userData.isDirectFromLink == 1 && item.label != 'AI Manager') && (this.userData.isDirectFromLink == 1 && item.label != 'Dashboard') &&  (this.userData.isDirectFromLink == 1 && item.label != 'Push an Action' &&  item.label != 'View Action History')  ? [] : item.route"
   class="item"
   [ngClass]="item.label == 'Dashboard' ? this.notificationCount?.length  && 'highlightNotification' : ' '"
   fxLayout="row"
   matRipple
   
  [routerLinkActive]=" (this.userData.isDirectFromLink == 1 && item.label != 'AI Manager') && (this.userData.isDirectFromLink == 1 && item.label != 'Dashboard') &&  (this.userData.isDirectFromLink == 1 && item.label != 'Push an Action' &&  item.label != 'View Action History' ) ? 'non-active': 'active' ">
  <mat-icon *ngIf="level === 0 && item.icon" [icIcon]="item.icon" class="item-icon" fxFlex="none"></mat-icon>
  <mat-icon *ngIf="level === 0 && item.svgIcon" [svgIcon]="item.svgIcon" class="item-icon" fxFlex="none"></mat-icon>
  <span class="item-label" fxFlex="auto">{{ item.label }}</span>

  <mat-icon  *ngIf="(this.userData.isDirectFromLink == 1 && item.label != 'AI Manager') && (this.userData.isDirectFromLink == 1 && item.label != 'Dashboard') &&  (this.userData.isDirectFromLink == 1 && item.label != 'Push an Action' &&  item.label != 'View Action History') "  class="item-icon" [icIcon]="icLock" fxFlex="none"> </mat-icon>
 

  
  <span *ngIf="item.label == 'Dashboard' ? this.notificationCount?.length  : false"
        class="item-badge "
        fxFlex="none">
       <img src="./../../../../assets/img/survey-ai/notificationBell.gif" alt="" class="w-12 h-12"> 
      </span>       
</a>


<!--<a (click)="openNotificationModal($event)" *ngIf="isLink(item) && !isFunction(item.route) && item.route === '/send-notification'"-->
<!--   href="javascript:void(0)"-->
<!--   class="item text-capitalize"-->
<!--   fxLayout="row"-->
<!--   matRipple-->
<!--   routerLinkActive="active">-->
<!--  <mat-icon *ngIf="level === 0" [icIcon]="item.icon" class="item-icon" fxFlex="none"></mat-icon>-->
<!--  <span class="item-label" fxFlex="auto">{{ item.label }}</span>-->
<!--  <span *ngIf="item.badge"-->
<!--        [ngClass]="[item.badge.bgClass, item.badge.textClass]"-->
<!--        class="item-badge"-->
<!--        fxFlex="none">{{ item.badge.value }}</span>-->
<!--</a>-->

<div (click)="item.route()"
     *ngIf="isLink(item) && isFunction(item.route)"
     class="item"
     fxLayout="row"
     matRipple
     routerLinkActive="active">
  <mat-icon *ngIf="level === 0" [icIcon]="item.icon" class="item-icon" fxFlex="none"></mat-icon>
  <span class="item-label" fxFlex="auto">{{ item.label }}</span>
  <span *ngIf="item.badge"
        [ngClass]="[item.badge.bgClass, item.badge.textClass]"
        class="item-badge"
        fxFlex="none">{{ item.badge.value }}</span>
</div>


<ng-container *ngIf="isDropdown(item)">
  <div (click)="toggleOpen()"
       [class.active]="isOpen || isActive"
       [class.open]="isOpen"
       class="item"
       fxLayout="row"
       matRipple>
    <mat-icon *ngIf="level === 0" [icIcon]="item.icon" class="item-icon" fxFlex="none"></mat-icon>
    <span class="item-label" fxFlex="auto">{{ item.label }}</span>
    <span *ngIf="item.badge"
          [ngClass]="[item.badge.bgClass, item.badge.textClass]"
          class="item-badge"
          fxFlex="none">{{ item.badge.value }}</span>
    <mat-icon [icIcon]="icKeyboardArrowRight" class="item-dropdown-icon" fxFlex="none"></mat-icon>
  </div>
  <div [@dropdown]="isOpen" class="item-dropdown">
    <vex-sidenav-item *ngFor="let subItem of item.children" [item]="subItem" [level]="level + 1"></vex-sidenav-item>
  </div>
</ng-container>


<ng-container *ngIf="isSubheading(item)">
  <div class="subheading">{{ item.label }}</div>
  <vex-sidenav-item *ngFor="let subItem of item.children" [item]="subItem" [level]="0"></vex-sidenav-item>
</ng-container>



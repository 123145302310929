<div #originRef
     (click)="showPopover(originRef)"
     [class.bg-hover]="dropdownOpen"
     class="relative flex items-center p-3 rounded cursor-pointer select-none trans-ease-out hover:bg-hover"
     matRipple>
  <!-- <div class="font-medium leading-snug body-1 text-capitalize ltr:mr-3 rtl:ml-3" fxHide.xs>{{'loggedInUser.name' | translate: {'name': AdminDetails.name} }} </div> -->
  <div class="flex items-center justify-center rounded-full h-9 w-9 text-primary bg-primary-light">
    <mat-icon [icIcon]="icPerson"></mat-icon>
  </div>
</div>

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vex-manager-onboard-confirmation-dailog',
  templateUrl: './manager-onboard-confirmation-dailog.component.html',
  styleUrls: ['./manager-onboard-confirmation-dailog.component.scss']
})
export class ManagerOnboardConfirmationDailogComponent implements OnInit {

  message = 'Are you sure?';
  confirmButtonText = 'Yes';
  cancelButtonText = 'Cancel';
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
      private dialogRef: MatDialogRef<ManagerOnboardConfirmationDailogComponent>
  ) { }

  ngOnInit(): void {
  }

  confirm(){
    this.dialogRef.close(true)
  }
  
  cancel(){
    this.dialogRef.close(false)
  }
}

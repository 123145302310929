<mat-dialog-content class="inivate-onboard-dialog !pb-0 ">

    <mat-horizontal-stepper class="pt-8 pb-0 pr-0 inivate-onboard"
      [ngClass]="'selectedStep' + (this.selectedIndex ? this.selectedIndex : 0)"
      (selectionChange)="selectionChange($event)" [selectedIndex]="selectedIndex" [linear]="isLinear" #stepper>
  
  
      <!-- step 1  -->
      <mat-step [stepControl]="challengeRecommendationForm" [optional]="this.isOptional">
        <div class="relative">
          <div>
            <h2 class="pt-2 font-bold text-center xs:text-base sm:text-2xl md:text-2xl lg:text-3xl">
              {{'Recommended Action'}}</h2>
          </div>
  
  
  
          <div class="flex justify-center mt-6 ">
            <h4 *ngIf="this.data?.recommendations?.length" class="font-semibold" style="color:#0052CC;">{{
              (this.challengeRecommendationsIndex + 1) }} </h4>
            <h4 class="ml-1">{{ "of"+" " + (this.data?.recommendations?.length ? this.data?.recommendations?.length : 0)}}
            </h4>
          </div>
  
          <div class="pb-32 slide_item sm:pb-0" *ngIf="this.data?.recommendations?.length">
  
            <div class="relative w-full rounded-xl slider_shadow"
              [ngStyle]="{ 'background-color': this.data?.recommendations[this.challengeRecommendationsIndex]?.isSelected == 1 ?  this.data?.recommendations[this.challengeRecommendationsIndex]?.kpiAreaColor : '#fff'}">
  
              <div class="px-4 pt-4 sm:flex "
                [ngClass]="this.data?.recommendations[this.challengeRecommendationsIndex]?.keyword?.length > 0 ?'pb-10':'pb-4' ">
  
                <div class="block sm:hidden">
                  <div class="w-auto customize-btn">
                    <button class="flex h-8 px-3 py-1 mt-1 text-white rounded-l-md" style="background-color: #0052CC;"
                      (click)="this.isChallengeEdit = !this.isChallengeEdit">
                      <img src="../../assets/img/inivated-user/customEdit.svg" class="h-5 mr-1">{{isChallengeEdit ?
                      "Customize" : 'Save'}} </button>
                  </div>
  
                </div>
  
                <!-- big screen -->
                <div class="items-center hidden w-1/3 sm:flex sm:visible">
                  <img [src]="this.data?.recommendations[this.challengeRecommendationsIndex]?.challengeImage" alt=""
                    class="w-32 m-auto">
                </div>
  
                <!-- small screen -->
                <div class="flex items-center visible w-full sm:hidden">
                  <img [src]="this.data?.recommendations[this.challengeRecommendationsIndex]?.challengeImage" alt=""
                    class="w-32 m-auto">
                </div>
  
  
  
                <div class="w-full sm:w-2/3 inivate-challenge-details">
  
                  <div class="hidden sm:block">
                    <div class="w-auto customize-btn">
                      <button class="flex h-8 px-3 py-1 mt-1 text-white rounded-l-md" style="background-color: #0052CC;"
                        (click)="this.isChallengeEdit = !this.isChallengeEdit">
                        <img src="../../assets/img/inivated-user/customEdit.svg" class="h-5 mr-1">{{isChallengeEdit ?
                        "Customize" : 'Save'}} </button>
                    </div>
  
                  </div>
  
  
  
                  <!-- challenge template input -->
                  <div *ngIf="isChallengeEdit" class="flex justify-between ">
                    <div>
                      <h2 class="text-lg font-bold">
                        {{this.data?.recommendations[this.challengeRecommendationsIndex]?.meta_title ?
                        this.data?.recommendations[this.challengeRecommendationsIndex]?.meta_title :
                        this.data?.recommendations[this.challengeRecommendationsIndex]?.title}}</h2>
                    </div>
                  </div>
  
                  <!-- challenge template desc -->
                  <div *ngIf="!isChallengeEdit" class="mt-2">
                    <div class="">
                      <mat-form-field class="w-full challengeEditInput ">
                        <input matInput
                          [(ngModel)]="this.data?.recommendations[this.challengeRecommendationsIndex].meta_title"
                          [ngModelOptions]="{standalone: true}" required>
                      </mat-form-field>
                    </div>
  
                  </div>
  
  
  
                  <div class="pb-8">
                    <div class=" focus:outline-none"
                      [ngStyle]="!isChallengeEdit && {'border': '1px solid #EEF3FB', 'border-radius': '7px'}"
                      [ngClass]="!isChallengeEdit && 'bg-white p-4' " (blur)="onSave($event)"
                      [contentEditable]="!isChallengeEdit"
                      [innerHTML]="this.data?.recommendations[this.challengeRecommendationsIndex].description">
                    </div>
                  </div>
  
                  <!-- btn -->
                  <button class="curousel-prev-btn"
                    [ngClass]="(1  != this.challengeRecommendationsIndex + 1) ? 'visible' : 'hidden'" (click)="pre()"><img
                      src='../../assets/img/inivated-user/left.svg'></button>
                  <button class="curousel-next-btn"
                    [ngClass]="(this.data?.recommendations.length != this.challengeRecommendationsIndex + 1) ? 'visible' : 'hidden'"
                    (click)="next()"><img src='../../assets/img/inivated-user/right.svg'></button>
                </div>
  
  
              </div>
  
  
  
            </div>
  
  
            <div class="relative flex justify-center -bottom-12 sm:bottom-6"
              *ngIf="this.data?.recommendations?.length && isChallengeEdit">
              <button type="submit"
                [ngClass]="this.data?.recommendations[this.challengeRecommendationsIndex]?.isSelected == 1 ? 'challengeSelected': 'bg-white' "
                (click)="selectChallenge()" class="rounded-lg select-action-btn">
  
                {{ this.data?.recommendations[this.challengeRecommendationsIndex]?.isSelected == 1 ? 'Selected': "Select this Action"}}
              </button>
            </div>
  
          </div>
  
          <div *ngIf="!this.data?.recommendations?.length" class="my-10 text-center xs:text-base sm:text-2xl md:text-2xl">
            <ngx-shimmer-loading height="50px" [borderRadius]="'0px'" [width]="'650px'"></ngx-shimmer-loading>
          </div>
  
  
  
  
  
          <div class="flex justify-end responsive-action-btn">
  
            <button type="submit" mat-raised-button
              [disabled]="!this.data?.recommendations?.length || !isChallengeEdit  || (this.data?.recommendations[this.challengeRecommendationsIndex]?.isSelected == 0)"
              (click)="saveChallenge(this.data?.recommendations[this.challengeRecommendationsIndex])"
              class="flex items-center pt-1 pb-1 pl-6 rounded-lg next-btn">
              Next
              <mat-icon [icIcon]="icRightArrow"></mat-icon>
            </button>
          </div>
  
  
        </div>
      </mat-step>
  
  
      <mat-step [stepControl]="launchScreen" [optional]="this.isOptional">
  
        <div class="team-title-sec">
          <img src="../../assets/img/inivated-user/teamSize.svg" class="w-32 pt-3 pb-2 m-auto sm:pt-1 sm:w-auto">
          <h2 class="font-bold text-center sm:pt-3 xs:text-base sm:text-2xl md:text-2xl "
            [innerHTML]="'Who do you want to launch it for?'"></h2>
  
        </div>
  
        <div class="flex pt-8 select-employee">
          <button mat-button class="" [ngClass]="isActionForEmployeeOrTeam && 'active'"
            (click)="switchEmployeeType('employee')">
            An employee
          </button>
          <button mat-button [ngClass]="!isActionForEmployeeOrTeam && 'active'" (click)="switchEmployeeType('team')">
            A team
          </button>
        </div>
  
  
  
        <div class="flex flex-row-reverse justify-between responsive-action-btn">
  
          <button type="submit" mat-raised-button matStepperNext (click)="saveEmployeeType()"
            class="flex items-center pt-1 pb-1 pl-6 rounded-lg next-btn">
            Next
            <mat-icon [icIcon]="icRightArrow"></mat-icon>
          </button>
  
          <button type="submit" mat-raised-button matStepperPrevious class="pt-1 pb-1 pr-6 rounded-lg back-btn">
            <mat-icon [icIcon]="icLeftArrow"></mat-icon>
            Back
          </button>
        </div>
  
      </mat-step>
  
  
      <mat-step [stepControl]="teamNameFormGroup" [optional]="this.isOptional">
        <form [formGroup]="teamNameFormGroup" class="relative">
          <div *ngIf="!isActionForEmployeeOrTeam">
  
  
  
  
            <div class="team-title-sec">
              <img src="../../assets/img/inivated-user/groupTeam.svg" class="w-32 pt-3 pb-2 m-auto sm:pt-1 sm:w-auto">
              <h2 class="font-bold text-center sm:pt-8 xs:text-base sm:text-2xl md:text-2xl lg:text-3xl">Tell us your team's name</h2>
              <p *ngIf="this.data?.isFirstChallenge >= 1 " class="text-center">
                You can either mention a team that exists already, or you can create a brand new team.
              </p>
            </div>
  
            <div class="flex mt-4 select-employee" *ngIf="this.data?.isFirstChallenge >= 1">
              <button mat-ripple class="" [ngClass]="isNewTeamSelected && 'active'" (click)="switchTeamType('newTeam')">
                Create Team
              </button>
              <button mat-ripple [ngClass]="!isNewTeamSelected && 'active'" (click)="switchTeamType('existingTeam')">
                Existing Team
              </button>
            </div>
  
            <div class="flex flex-col w-full pt-8 sm:pt-4 " *ngIf="isNewTeamSelected">
              <!-- <label class="pb-1 font-semibold">Team name</label> -->
              <mat-form-field>
                <input matInput placeholder="Enter your team name" formControlName="teamName" required>
              </mat-form-field>
            </div>
  
            <div class="flex flex-col w-full pt-8 sm:pt-4" *ngIf="!isNewTeamSelected">
  
              <label class="pb-1 font-semibold">Please select department</label>
              <mat-form-field class="manager-team-selection">
                <mat-select placeholder="Please select department" (selectionChange)="onDepartmentChange($event.value)">
                  <mat-option *ngFor="let item of  this.departmentList;" [value]="item.id"
                    (click)="onDepartmentSelect(item)">
                    {{item.title}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
  
  
              <label class="pb-1 font-semibold">Select Team Name</label>
              <mat-form-field class="manager-team-selection">
                <mat-select formControlName="teamId" placeholder="Please select team"
                  (selectionChange)="teamOnChange($event.value)">
                  <mat-option *ngFor="let item of  this.teamList;" [value]="item.id" (click)="getNotification(item)">
                    {{item.title}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
  
          </div>
  
          <div *ngIf="isActionForEmployeeOrTeam">
            <div class="team-title-sec">
              <img src="../../assets/img/inivated-user/groupTeam.svg" class="w-32 pt-3 pb-2 m-auto sm:pt-1 sm:w-auto">
              <h2 class="font-bold text-center sm:pt-8 xs:text-base sm:text-2xl md:text-2xl lg:text-3xl">Tell us your
                employee
                name</h2>
              <!-- <p>
                    You can either mention a team that exists already, or you can create a brand new team.
                  </p> -->
            </div>
            <div class="flex flex-col w-full pt-8 sm:pt-4 ">
              <!-- <label class="pb-1 font-semibold">Employee name</label> -->
              <mat-form-field>
                <input matInput placeholder="Enter your employee name" formControlName="teamName" required>
              </mat-form-field>
            </div>
          </div>
  
  
          <div class="flex flex-row-reverse justify-between responsive-action-btn">
            <button type="submit" mat-raised-button
              [disabled]="!this.teamNameFormGroup?.value?.teamName?.length  &&  !this.teamNameFormGroup?.value?.teamId "
              (click)="saveTeamData()" class="flex items-center pt-1 pb-1 pl-6 rounded-lg next-btn">
              Next
              <mat-icon [icIcon]="icRightArrow"></mat-icon>
            </button>
            <button type="submit" mat-raised-button matStepperPrevious class="pt-1 pb-1 pr-6 rounded-lg back-btn">
              <mat-icon [icIcon]="icLeftArrow"></mat-icon>
              Back
            </button>
          </div>
  
  
        </form>
      </mat-step>
  
      <mat-step [stepControl]="departmentNameFormGroup" *ngIf="isNewTeamSelected" [optional]="this.isOptional">
        <form [formGroup]="departmentNameFormGroup" class="relative">
  
  
          <div>
            <img src="../../assets/img/inivated-user/department.svg" class="w-32 pt-3 pb-2 m-auto sm:pt-1 sm:w-auto">
            <h2 class="pt-2 font-bold text-center xs:text-base sm:text-2xl md:text-2xl lg:text-3xl">Tell us about your
              department </h2>
            <h4 class="text-center">{{ this.data?.isFirstChallenge >= 1? "Please enter department name or select form
              existing team " : "Please enter department name below" }}</h4>
          </div>
  
          <div class="flex pt-8 select-employee" *ngIf="this.data?.isFirstChallenge >= 1">
            <button mat-ripple class="" style="width: 180px!important;" [ngClass]=" isNewChallengeOrExisting && 'active'"
              (click)="switchDepartment('new')">
              New department
            </button>
            <button mat-ripple style="width: 180px!important;" [ngClass]="!isNewChallengeOrExisting && 'active'"
              (click)="switchDepartment('existing')">
              Select department
            </button>
          </div>
  
          <div class=" pt-8 pb-4">
  
            <div *ngIf="isNewChallengeOrExisting" class="flex flex-col w-full">
  
              <mat-form-field>
                <input matInput placeholder="Enter your department name" formControlName="departmentName" required>
              </mat-form-field>
            </div>
  
  
            <div *ngIf="!isNewChallengeOrExisting" class="flex flex-col w-full">
  
              <label class="pb-1 font-semibold">Please select department</label>
              <mat-form-field class="manager-team-selection">
                <mat-select placeholder="Please select department" formControlName="departmentId"
                  (selectionChange)="onDepartmentChange($event.value)">
                  <mat-option *ngFor="let item of  this.departmentList;" [value]="item.id"
                    (click)="onDepartmentSelect(item)">
                    {{item.title}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
  
  
  
  
          <div class="flex flex-row-reverse justify-between responsive-action-btn">
            <button type="submit" matStepperNext mat-raised-button [disabled]="this.departmentNameFormGroup.invalid"
              class="flex items-center pt-1 pb-1 pl-6 rounded-lg next-btn">
              Next
              <mat-icon [icIcon]="icRightArrow"></mat-icon>
            </button>
            <button type="submit" mat-raised-button matStepperPrevious class="pt-1 pb-1 pr-6 rounded-lg back-btn">
              <mat-icon [icIcon]="icLeftArrow"></mat-icon>
              Back
            </button>
  
          </div>
  
  
        </form>
      </mat-step>
  
  
      <mat-step [stepControl]="launchScreen" [optional]="this.isOptional">
        <div class="relative weekly-action">
          <form [formGroup]="weeklyActionGroup" class="">
            <div>
              <h2 class="font-bold text-center xs:text-base sm:text-2xl md:text-2xl lg:text-3xl">{{"Set weekly action
                nudges"}}</h2>
              <h4 class="pt-2 text-base font-semibold text-center">{{'These are the nudges employees will receive in your
                communication channel'}}</h4>
            </div>
  
  
  
            <div class="flex justify-center gap-3 pt-4 ">
              <div *ngFor="let day of weeklyAction"
                [ngStyle]="day?.isSelected && { 'background-color' : '#0052CC' , 'color': '#fff' }"
                class="flex w-16 py-1 border rounded-lg cursor-pointer days-section" (click)="selectDay(day)">
                <h4 class="m-auto text-sm uppercase"> {{day?.shortTitle}}</h4>
              </div>
            </div>
            <span class="flex justify-center pt-2 m-auto text-sm font-medium">We recommended 2 nudges per week</span>
  
  
            <div class="flex flex-col justify-center">
              <h4 class="pt-3 m-auto text-lg font-medium"> {{'Set nudge time'}}</h4>
  
              <div class="flex justify-center">
  
                <mat-form-field class="w-64">
                  <input matTimepicker required [ngModel]="defaultTime" mode="24h" placeholder="HH:MM "
                    formControlName="time">
                  <mat-icon class="absolute -right-1" [icIcon]="clock" color="grey"></mat-icon>
  
                </mat-form-field>
  
              </div>
  
              <h3 *ngIf="this.isAnySelected == false" class="pt-8 pb-24 text-center "
                [innerHTML]="'Choose the day and time for the nudge <br> you wish to set.'"> </h3>
  
  
              <div class="notification-section" *ngIf="this.isAnySelected ">
                <div *ngFor="let day of weeklyAction;let i = index">
                  <div *ngIf="day.isSelected == true">
                    <h4 class="text-sm"><b>{{day.title}}</b> nudge</h4>
  
                    <mat-form-field class="w-full challengeEditInput ">
                      <input placeholder="Please enter notification message" matInput
                        [(ngModel)]="weeklyAction[i].notification" class="w-4/5" [disabled]="!day.isEdit"
                        [ngModelOptions]="{standalone: true}" required>
  
                      <button class="absolute flex px-2 py-1 text-xs text-white save-btn" (click)="customize(day)">
                        <img src="../../assets/img/inivated-user/customEdit.svg" class="pr-1" />{{day.isEdit ? 'Save'
                        :'Customize'}}</button>
                    </mat-form-field>
                  </div>
  
                </div>
              </div>
  
            </div>
  
            <div class="flex justify-between responsive-action-btn">
              <button type="submit" mat-raised-button matStepperPrevious class="pb-1 pr-6 rounded-lg back-btn">
                <mat-icon [icIcon]="icLeftArrow"></mat-icon>
                Back
              </button>
  
              <button type="button" (mouseenter)="showDiv()" (mouseleave)="hideDiv()" *ngIf="isPreviewBtnDisabled()"
                class="px-2 pb-1" style="color: #0052CC;">
                View example
              </button>
  
              <div *ngIf="isDivVisible" class="hover-div" class="flex justify-center w-full preview-btn "
                [ngClass]="isPreviewBtnDisabled() ? 'visible': 'hidden' ">
  
                <div class="relative card preview">
                  <div class="absolute top-0 right-0 p-2 text-white sample-btn" style="background-color: #0052CC;">
                    Sample
                  </div>
  
                  <h2 class="text-2xl font-bold">{{'Hi there,'}}</h2>
                  <h4 class="pt-2 text-sm leading-normal">{{"Just dropping by for our weekly check-in to see how you're
                    progressing with the "}}
                    <span class="font-bold"> "{{this.selectedChallengeTemplate?.meta_title}}" </span> {{"initiative"}}
                  </h4>
  
                  <h4 class="pt-3 text-sm leading-normal">
                    {{this.weeklyAction[0].notification}}
                  </h4>
  
                  <button class="w-20 mt-4 mr-2 text-white rounded-lg h-9" style="background-color: #FF5757; ">
                    No</button>
                  <button class="w-20 mt-4 text-white rounded-lg h-9" style="background-color: #00BF63; ">Yes</button>
  
                  <!-- <h6 class="mt-3 text-sm font-medium" >{{'Best wishes'}}</h6>
                    <h6 class="text-sm font-bold">{{'The Empida team'}}</h6> -->
  
                </div>
              </div>
  
              <button type="submit" mat-raised-button [disabled]="isSubmitActionBtnDisabled()"
                (click)="sendWeeklyNotification()" class="flex items-center pb-1 pl-6 rounded-lg next-btn">
                Next
                <mat-icon [icIcon]="icRightArrow"></mat-icon>
              </button>
            </div>
  
          </form>
        </div>
      </mat-step>
  
      <mat-step [stepControl]="launchScreen" [optional]="this.isOptional">
        <div class="relative">
  
  
          <div>
            <h2 class="font-bold text-center xs:text-base sm:text-2xl md:text-2xl lg:text-3xl"> Summary </h2>
          </div>
  
          <div class="select-launch-section">
            <div class="gap-4 ">
  
              <form class="invisible w-1 h-1" [formGroup]="challengeLaunchDates">
                <mat-form-field class="invisible">
                  <input formControlName="startDate" [min]="this.currentDate" [max]="this.maxDate" class="invisible"
                    matInput [matDatepicker]="picker">
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </form>
  
  
              <div class="flex justify-center">
                <div class="flex flex-col items-center pt-12 pb-4 sm:flex-row sm:justify-around challenge-summary ">
                  <div class="mb-12 sm:mb-2">
                    <img src="./../../assets/img/inivated-user/team.svg" class="absolute w-24 -top-5" alt="Team">
                    <h2 class="pt-6 text-lg font-semibold">{{'1-10'}}</h2>
                    <h4 class="text-sm font-semibold text-gray">Team </h4>
                  </div>
  
                  <div class="mb-12 sm:mb-2">
                    <img src="./../../assets/img/inivated-user/performance.svg" class="absolute w-16 -top-8"
                      alt="Focused">
                    <h2 class="pt-12 text-base font-semibold leading-normal text-center">
                      {{this.selectedChallengeTemplate?.improvementArea}} </h2>
                    <h4 class="text-sm font-semibold text-gray">Focus area</h4>
                  </div>
                </div>
              </div>
  
              <div class="flex justify-center ">
  
                <div class="px-6 py-3 text-base rounded-lg w-max" style="background-color: #F3F6FF; width: fit-content;">
                  {{'Employees will do the'}}
                  <span class="font-bold">{{this.selectedChallengeTemplate?.meta_title }}</span>{{' action'}}
                </div>
              </div>
  
  
              <div class="flex flex-col mt-4 text-center ">
                <h4 class="text-lg font-bold ">Nudge Schedule</h4>
  
                <div class="flex flex-col items-center pt-4 sm:flex-row sm:justify-around ">
  
                  <div class="flex items-center pb-4 text-left ">
                    <h4 class="mr-1 text-base">Day: </h4>
  
                    <div class="text-lg " *ngFor="let item of weeklyAction ">
                      <div *ngIf="item.isSelected == true"
                        class="flex items-center justify-center w-10 h-10 mr-2 text-xs text-white capitalize rounded-full "
                        style="background-color: #0052CC;">
                        {{item.shortTitle}}
                      </div>
                    </div>
                  </div>
  
                  <div class="flex items-center pb-4 text-left ">
                    <h4 class="mr-1 text-base ">Time: </h4>
                    <span class="text-base font-bold" *ngIf="this.weeklyActionGroup?.value?.time">
                      {{this.getHourMins(this.weeklyActionGroup?.value?.time).hours}}:{{this.getHourMins(this.weeklyActionGroup?.value?.time).minutes}}</span>
                  </div>
  
                </div>
  
              </div>
  
              <div class="flex flex-col pb-8 mt-4 text-center ">
                <h4 class="text-lg font-bold ">Now, let's select launch date below</h4>
                <span class="text-base italic" *ngIf="!this.challengeLaunchDates?.value?.startDate">{{ 'Recommended '+
                  (this.recommendedDate | date : 'MMMM d, y') }} </span>
              </div>
  
  
              <div class="flex flex-col items-center sm:flex-row sm:justify-around ">
                <div class="flex flex-col pb-8 text-left" *ngIf="this.challengeLaunchDates?.value?.startDate">
                  <h4 class="text-base ">Start date:</h4>
                  <span class="text-lg font-bold">{{this.challengeLaunchDates?.value?.startDate | date : 'MMMM d,
                    y'}}</span>
                </div>
  
                <div class="flex flex-col pb-8 text-left" *ngIf="this.challengeLaunchDates?.value?.startDate">
                  <h4 class="text-base ">End date:</h4>
                  <span class="text-lg font-bold">{{this.challengeLaunchDates?.value?.endDate | date : 'MMMM d,
                    y'}}</span>
                </div>
              </div>
  
              <div class="text-center ">
                <button matInput (click)="picker.open()" class="h-12 rounded-lg calender-btn">
                  <div class="flex items-center justify-center gap-2">
                    <img src="assets/img/on-board/calender.png">
                    <span>Calendar</span>
                  </div>
                </button>
                <h4 class="mt-3 text-base font-semibold ">Actions are 4 weeks at a time.</h4>
              </div>
  
  
  
  
              <div class="flex flex-row-reverse justify-between mt-14 responsive-action-btn">
  
                <button type="submit" mat-raised-button matStepperNext
                  [disabled]="!this.challengeLaunchDates?.value?.startDate"
                  class="flex items-center pt-1 pb-1 pl-6 rounded-lg next-btn">
                  Next
                  <mat-icon [icIcon]="icRightArrow"></mat-icon>
                </button>
                <button type="submit" mat-raised-button matStepperPrevious class="pt-1 pb-1 pr-6 rounded-lg back-btn">
                  <mat-icon [icIcon]="icLeftArrow"></mat-icon>
                  Back
                </button>
              </div>
  
            </div>
          </div>
        </div>
      </mat-step>
  
      <!-- step 11 -->
      <mat-step [stepControl]="launchScreen" [optional]="this.isOptional">
  
        <div *ngIf="isSlackModelOpen" class="flex flex-col items-center justify-center w-full" style="height: 420px;">
          <div class="lds-ripple">
            <div></div>
            <div></div>
          </div>
          <h6>Please wait we are working on your action</h6>
        </div>
  
        <div *ngIf="!isSlackModelOpen">
          <div>
            <h2 class="pt-4 font-bold text-center xs:text-base sm:text-2xl md:text-2xl lg:text-3xl">Select your preferred
              channel</h2>
          </div>
  
          <div class="flex flex-col justify-center gap-12 mt-6 notifications-text ">
            <h3 *ngFor="let item of preferredChannel" class="m-auto text-sm text-center sm:text-xl">{{item.title}} </h3>
          </div>
  
          <div class="mt-8 text-center">
            <span class="text-lg font-semibold sm:text-2xl ">
              Select a channel
            </span>
          </div>
  
          <div class="flex mt-5 justify-evenly">
  
            <div *ngIf="this.notificationType == 2" class="flex flex-col items-center cursor-pointer"
              (click)="sendNotification('slack')">
              <button matRipple class="" [disabled]="this.isChannelTypeBtnDisabled" type="submit">
                <img src="../../assets/img/inivated-user/slack.png" class="w-20">
              </button>
              <h4 class="font-bold ">Slack</h4>
            </div>
  
            <div *ngIf="this.notificationType == 1" class="flex flex-col items-center cursor-pointer"
              (click)="sendNotification('email')">
              <button matRipple [disabled]="this.isChannelTypeBtnDisabled" type="submit">
                <img src="../../assets/img/inivated-user/email.svg" class="w-20">
              </button>
              <h4 class="font-bold "> Email </h4>
            </div>
  
            <div *ngIf="this.notificationType == 0" class="flex flex-col items-center cursor-pointer"
              (click)="sendNotification('slack')">
              <button matRipple class="" [disabled]="this.isChannelTypeBtnDisabled" type="submit">
                <img src="../../assets/img/inivated-user/slack.png" class="w-20">
              </button>
              <h4 class="font-bold ">Slack</h4>
            </div>
  
            <div *ngIf="this.notificationType == 0" class="flex flex-col items-center cursor-pointer"
              (click)="sendNotification('email')">
              <button matRipple [disabled]="this.isChannelTypeBtnDisabled" type="submit">
                <img src="../../assets/img/inivated-user/email.svg" class="w-20">
              </button>
              <h4 class="font-bold "> Email </h4>
            </div>
  
            <!-- <div class="flex flex-col items-center cursor-pointer" (click)="sendNotification('slack')">
                <button matRipple class="" type="submit">
                <img src="../../assets/img/inivated-user/slack.png" class="w-24">
                </button>
                <h4 class="font-bold ">Slack</h4>
              </div>
    
              <div class="flex flex-col items-center cursor-pointer" (click)="sendNotification('email')">
                <button matRipple type="submit">
                  <img src="../../assets/img/inivated-user/email.svg" class="w-24">
                </button>
                <h4 class="font-bold "> Email </h4>
              </div> -->
  
          </div>
        </div>
      </mat-step>
  
      <!-- step 12 -->
      <mat-step [stepControl]="emailUpload" [optional]="this.isOptional" *ngIf="isNotificationType == 'email'">
  
        <div class="sm:px-4 " class="relative">
  
          <div class="w-full ">
  
  
            <div class="relative">
              <div class="flex items-center gap-4">
                <h2 class="font-bold leading-tight text-black xs:text-base sm:text-2xl md:text-2xl lg:text-3xl">Email
                </h2>
                <img src="../../assets/img/inivated-user/email.svg" class="w-10">
              </div>
              <!-- <img class="absolute" style="top: -8px; right:-64px;" src="../../assets/img/inivated-user/redArrowEmail.svg"> -->
            </div>
  
            <div class="flex flex-col mx-auto">
              <p class="pt-3 pb-8 mt-4 mb-4 text-2xl leading-snug text-center text-black">Enter or upload your teammate's
                emails </p>
            </div>
  
            <div class="flex w-full pl-1 pr-10 sm:pl-0">
              <div class="w-full h-16 emailsInput">
                <div class="relative">
                  <input type="text" [formControl]="this.emailInput" class="px-4 py-4" #emailValue
                    (keyup.enter)="addEmail()" placeholder="Enter your teammate's work-email">
                  <button mat-icon-button class="custom-icon-btn " type="submit" (click)="addEmail()"
                    [disabled]="this.emailInput.invalid">
                    <div class="flex items-center justify-center">
                      <img src="assets/img/on-board/checked.png" class="object-contain w-3 h-3" alt="">
                    </div>
                  </button>
                </div>
                <div [ngClass]="this.isVaildDomain ? 'block' : 'hidden' " class="mt-1 text-xs text-red">You’ve entered the
                  wrong work-email.</div>
              </div>
            </div>
  
  
            <div class="email-chipes" *ngIf="this.chipsEmails?.length">
              <mat-chip-list aria-label="Email selection">
                <ng-container *ngFor="let item of this.chipsEmails; let i = index">
                  <mat-chip>
                    <div class="flex items-center justify-center pr-2">
                      <span class="text-xs chip-title">{{item}}</span>
                      <button matChipRemove (click)="onRemoveEmail(i)" class="flex items-center">
                        <mat-icon [icIcon]="icClose"></mat-icon>
                      </button>
                    </div>
                  </mat-chip>
                </ng-container>
              </mat-chip-list>
            </div>
  
  
            <form [formGroup]="emailUpload" class="flex flex-row items-center justify-start gap-6 mt-5">
  
              <div class="flex justify-center">
                <input (change)="onFileChange($event)" multiple="false"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  id="getFile" class="hidden" type="file">
                <input formControlName="file" class="hidden">
                <div matRipple
                  class="flex items-center px-12 py-3 border rounded-lg cursor-pointer custom-shadow file-upload-btn custome-border"
                  for="files" onclick="document.getElementById('getFile').click()">
                  <p class="text-base font-semibold text-black custome-text"> {{emailUpload.value.file?.length ? 'File
                    uploaded' : 'Upload' }} </p>
                </div>
              </div>
  
              <a href="assets/sample_file/Employees-Email.xlsx" class="gap-1 text-sm font-semibold excel-download"
                target="_blank">
                {{'See sample' | titlecase}}
              </a>
  
            </form>
  
  
  
  
          </div>
  
          <div class="flex flex-row-reverse justify-between mt-10">
  
            <button type="submit" mat-raised-button (click)="saveEmails()"
              [disabled]="(this.emailUpload.value?.file?.length == 0) &&  (this.chipsEmails?.length == 0) && this.emailInput.invalid"
              class="flex items-center pt-1 pb-1 pl-6 rounded-lg next-btn">
              Next
              <mat-icon [icIcon]="icRightArrow"></mat-icon>
            </button>
            <button type="submit" mat-raised-button matStepperPrevious class="pt-1 pb-1 pr-6 rounded-lg back-btn">
              <mat-icon [icIcon]="icLeftArrow"></mat-icon>
              Back
            </button>
          </div>
  
  
  
        </div>
      </mat-step>
  
  
  
  
    </mat-horizontal-stepper>
  
  </mat-dialog-content>
import { ChangeDetectorRef, Component, Directive, HostListener, Input, OnInit } from '@angular/core';
import { trackByRoute } from '../../utils/track-by';
import { NavigationService } from '../../services/navigation.service';
import icRadioButtonChecked from '@iconify/icons-ic/twotone-radio-button-checked';
import icRadioButtonUnchecked from '@iconify/icons-ic/twotone-radio-button-unchecked';
import { LayoutService } from '../../services/layout.service';
import { ConfigService } from '../../services/config.service';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AuthServiceLocal } from '../../services/auth.service';
import { Router, RouterModule } from '@angular/router';
import { Module } from 'src/@vex/utils/systemEnums';
import { LocalStoreService } from 'src/@vex/services/local-store.service';
import icPerson from '@iconify/icons-ic/twotone-person';
import { MatDialog } from '@angular/material/dialog';
import { ToolbarUserDropdownComponent } from '../toolbar/toolbar-user/toolbar-user-dropdown/toolbar-user-dropdown.component';
import { PopoverService } from 'src/@vex/components/popover/popover.service';



@Component({
  selector: 'vex-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})

export class SidenavComponent implements OnInit {
  selectedModuleID: number;


  icPerson = icPerson

  @HostListener('sidenav') scrolling(){
    console.log('scrolling');
  }

  @Input() collapsed: boolean;
  collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;
  title$ = this.configService.config$.pipe(map(config => config.sidenav.title));
  imageUrl$ = this.configService.config$.pipe(map(config => config.sidenav.imageUrl));
  showCollapsePin$ = this.configService.config$.pipe(map(config => config.sidenav.showCollapsePin));

  items = [];
  trackByRoute = trackByRoute;
  icRadioButtonChecked = icRadioButtonChecked;
  icRadioButtonUnchecked = icRadioButtonUnchecked;
  loggedUser;

  dropdownOpen: boolean;

  constructor(public navigationService: NavigationService,
    private layoutService: LayoutService, private cd: ChangeDetectorRef,
    private configService: ConfigService, private translate: TranslateService,
    private auth: AuthServiceLocal, private router: Router, 
    private popover: PopoverService,
    private dialog :MatDialog, private store: LocalStoreService,) {
    this.navigationService.items.subscribe(items => {
      this.filterMenuItemsForUser(items);
    });


  }

  filterMenuItemsForUser(items) {
    const isAdminLogin = this.auth.isAdminLogin();
    if (isAdminLogin) {
      items.map((item: any) => {
        if (item.children) {
          item.children = item?.children?.filter(child => child.isShowForAdmin === 1)
        }
      });

      console.log('hello in side navigatioon ===>',items)
      this.navigationService.itemsArray = items;
    } else {
      const mainItems = items.filter(x => x.isShowForUser === 1);
      mainItems.map((item: any) => {
        if (item.children) {
          item.children = item?.children?.filter(child => child.isShowForUser === 1)
        }
      });
      this.navigationService.itemsArray = mainItems;
    }
  }

  ngOnInit() {
    this.loggedUser = this.auth.getUser();

    this.selectedModuleID = Number(this.store.getItem('selectedModuleID'));
    if(!this.selectedModuleID) {
      this.store.setItem('selectedModuleID', Module.Dashboard);
    }
  }

  onMouseEnter() {
    this.layoutService.collapseOpenSidenav();
  }

  onMouseLeave() {
    this.layoutService.collapseCloseSidenav();
  }

  toggleCollapse() {
    this.collapsed ? this.layoutService.expandSidenav() : this.layoutService.collapseSidenav();
  }

  redirectDashboard() {

   if( this.loggedUser.isDirectFromLink != 1) {
    //  this.layoutService.selectedModule.next(item?.moduleID);
    // this.store.setItem('selectedModuleID', item?.moduleID);
    this.router.navigate(['/'])
    const item = {
      isShowForUser: 1,
      label: "Dashboard",
      moduleID: 1,
      route: "/",
      svgIcon: "svgDashboard",
      type: "link",
    }
    this.layoutService.selectedModule.next(item?.moduleID);
    this.store.setItem('selectedModuleID', item?.moduleID);
  }
  }


  showPopover(originRef: HTMLElement) {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarUserDropdownComponent,
      origin: originRef,
      offsetY: 12,
      offsetX:28,
      
      // position: [
      //   {
      //     originX: 'center',
      //     originY: 'top',
      //     overlayX: 'center',
      //     overlayY: 'bottom'
      //   },
      //   {
      //     originX: 'end',
      //     originY: 'bottom',
      //     overlayX: 'end',
      //     overlayY: 'top',
      //   },
      // ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }

}

import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, CanActivateChild, CanDeactivate} from '@angular/router';
import { Observable } from 'rxjs';
import {LocalStoreService} from './local-store.service';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private  store: LocalStoreService) { }

  canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): boolean {

        
        const url: string = state.url;
        
    return this.checkLogin(url);
  }

  
  checkLogin(url: string) {
    if (this.isLoggedIn()) {
      
      return true;
    }
    else{
   
      if(url.includes('inivated-user')){
        console.log("in if conditon")
        this.router.navigate(['/invited-user']);
        return false;
      }else{
        console.log("in else conditon",url)

        // this.router.navigate(['/login'], {queryParams: { returnUrl: url }} );
        this.router.navigate(['/login']);
        return false;
      }

    }
  }
  public isLoggedIn(): boolean {
    let status = false;
    const loggedUSer = this.store.getItem('token');
    if (loggedUSer) {
      status = true;
    } else {
      status = false;
    }
    return status;
  }


  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkActivatedRoute();
  }

  checkActivatedRoute() {
    if (this.isAdminLogin()) {
      return true;
    }
    this.router.navigate(['permission-denied']);
    return false;
  }
  public isAdminLogin(): boolean {
    let status = false;
    const isAdmin = this.store.getItem('loggedUser').isAdmin;
    if (isAdmin === 1) {
      status = true;
    } else {
      status = false;
    }
    return status;
  }


}

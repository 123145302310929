<div matDialogTitle class="relative ">
    
</div>

<mat-dialog-content class="inivate-onboard-dialog">
    
    <div  class="px-6 pb-8 inivate-onboard-progress-bar">
        <mat-progress-bar mode="determinate" [value]="100"></mat-progress-bar>
    </div>


</mat-dialog-content>
    
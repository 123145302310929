<div class="gallery-modal">
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
        <h2 *ngIf="mode=='rewardPhoto'" class="m-0 headline" fxFlex="auto">{{'pageTitle.selectRewardPhoto' | translate}}
        </h2>
        <h2 *ngIf="mode=='photo'" class="m-0 headline" fxFlex="auto">{{'pageTitle.selectCoverPhoto' | translate}}</h2>

        <h2 *ngIf="mode=='wellnessImage'" class="m-0 headline" fxFlex="auto">Select Wellness Image</h2>
        <!-- <h4>bro</h4> -->


        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon [icIcon]="icClose"></mat-icon>
        </button>
    </div>
    <mat-divider class="-mx-6 text-border"></mat-divider>
    <mat-dialog-content fxLayout="column">


        <div class="grid grid-cols-4 gap-6 py-4 image-box-wrapper " *ngIf="mode=='rewardPhoto'">
            <div #ele class="mb-2 mr-2 cursor-pointer image" *ngFor="let image of rewardImagesArray; let i = index"
                (click)="selectImage(image.url, 'rewardPhoto' , ele)">
                <img [src]="image.localPath">

                <div class="selected-overlay">
                    <mat-icon color="primary" [icIcon]="icChecked"></mat-icon>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-4 gap-6 py-4 image-box-wrapper" *ngIf="mode=='photo'">
            <div #ele class="mb-2 mr-2 cursor-pointer image" *ngFor="let image of challengeImagesArray; let i = index"
                (click)="selectImage(image.url, 'photo',  ele)">
                <img [src]="image.localPath">

                
                <div class="selected-overlay">
                    <mat-icon color="primary" [icIcon]="icChecked"></mat-icon>
                </div>
            </div>
        </div>

        <div class="grid grid-cols-4 gap-6 py-4 image-box-wrapper" *ngIf="mode=='wellnessImage'">
            <div #ele class="mb-2 mr-2 cursor-pointer image" *ngFor="let image of wellnessImageArray; let i = index"
                (click)="selectImage(image.url, 'wellnessImage' , ele)">
                <img [src]="image.localPath">

                <div class="selected-overlay">
                    <mat-icon color="primary" [icIcon]="icChecked"></mat-icon>
                </div>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <div>
            <button mat-button type="button">
                <ngx-file-drop style="width: 100%;" *ngIf="!this.uploadImage.croppedProfileBase64"
                    dropZoneClassName="drop-zone" contentClassName="fileDrop" dropZoneLabel="Drop files here"
                    (onFileDrop)="dropped($event,'banner',this.mode)" multiple="false" accept="png">
                    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                        <div (click)="openFileSelector()">
                            <p>Upload Image</p>
                        </div>
                    </ng-template>
                </ngx-file-drop>
            </button>
        </div>

        <button mat-button mat-dialog-close type="button">{{'buttons.cancel' | translate}}</button>


        <button [disabled]="!photoUrl && !rewardPhotoUrl  && !wellnessImageUrl " (click)="submitImage()" color="primary" mat-button
            type="submit">{{'buttons.select' | translate}}</button>
    </mat-dialog-actions>
</div>